import React from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';
import Header from './header';
import MobHeader from './mobileHeader';
import SqrBtnMain from '../../components/squareBtnMain';
import SqrBtnSec from '../../components/squareBtnSec';
import ServiceCard from '../../components/serviceCard';
import BlogCard from '../../components/homeblogCard';
import CompanySwiper from '../../components/sliders/sliders';
import right_icon_white from '../../assets/icons/ico_arrow_right_w.svg';
import right_icon_blue from '../../assets/icons/ico_arrow_right_b.svg';
import ico_talent from '../../assets/icons/ico_talent.svg'
import ico_consulting from '../../assets/icons/ico_consulting.svg'
import ico_recruitment from '../../assets/icons/ico_recruitment.svg'
import ico_eor from '../../assets/icons/ico_eor.svg'
import ico_human_resource from '../../assets/icons/ico_human_resource.svg'
import ico_business_process from '../../assets/icons/ico_business_process.svg'
import ico_manage_service from '../../assets/icons/ico_manage_service.svg'
import blog1 from '../../assets/home/blog1.jpeg';
import blog2 from '../../assets/home/blog2.jpeg';
import blog3 from '../../assets/home/blog3.jpeg';
import Google from '../../assets/swiper/company/Google.jpg';
import Apple from '../../assets/swiper/company/Apple.jpg';
import Amazon from '../../assets/swiper/company/Amazon.jpg';
import Airbnb from '../../assets/swiper/company/Airbnb.jpg';
import Pinterest from '../../assets/swiper/company/Pinterest.jpg';
import Snap from '../../assets/swiper/company/Snap.jpg';
import Youtube from '../../assets/swiper/company/Youtube.jpg';
import Linkedin from '../../assets/swiper/company/Linkedin.jpg';
import Alibaba from '../../assets/swiper/company/Alibaba.jpg';
import Tencent from '../../assets/swiper/company/Tencent.jpg';
import Baidu from '../../assets/swiper/company/Baidu.jpg';
import TikTok from '../../assets/swiper/company/TikTok.jpg';
import Waymo from '../../assets/swiper/company/Waymo.jpg';
import Cruise from '../../assets/swiper/company/Cruise.jpg';
import Didi from '../../assets/swiper/company/Didi.jpg';
import TuSimple from '../../assets/swiper/company/TuSimple.jpg';
import Lego from '../../assets/swiper/company/Lego.jpg';
import Samsung from '../../assets/swiper/company/Samsung.jpg';
import Starbucks from '../../assets/swiper/company/Starbucks.jpg';
import Goldman from '../../assets/swiper/company/Goldman Sachs.jpg';

import BottomImg from '../../assets/home/bottomImg.jpeg';
import GreatPlaceImg from '../../assets/home/greatPlace.png';

import { withStyles } from '@material-ui/core/styles';
import { Color } from '../../assets/color';
import { Helmet } from 'react-helmet';



const style =theme => ({
    root: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
        
    },
    buttonGroup: {
        [theme.breakpoints.up('mob')]: {
            display:'none'
        },
        [theme.breakpoints.up('desktop')]: {
            display:'flex'
        },
    },
    blockSpacing: {
        [theme.breakpoints.up('mob')]: {
            padding: '0px 24px'
        },
        [theme.breakpoints.up('tableth')]: {
            padding: '0px 32px'
        },
        [theme.breakpoints.up('desktop')]: {
            padding: '0px 120px'
        },
        [theme.breakpoints.up('largedesktop')]: {
            padding: '0px 240px',
        },
    },
    block: {
        display:'flex', 
        flexDirection:'row', 
        // justifyContent:'space-between', 
    },
    introBlock: {
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
    },
    intro: {
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: 600,
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: 720,
        },
    },
    cardBlock: {
        display:'flex',
        flexDirection:'column',
        [theme.breakpoints.up('mob')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingTop: 120
        },
        [theme.breakpoints.up('desktop')]: {
            paddingTop: 200,
        },
        [theme.breakpoints.up('largedesktop')]: {
            paddingTop: 240,
        },
    },
    cardTitle: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 40,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingBottom: 32,
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 60
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: 80,
        }
    },
    cardGroup: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 40,
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingBottom: 32,
            margin: -12
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 60,
            margin: -16
        },
        [theme.breakpoints.up('desktop')]: {
            paddingBottom: 80,
            margin: -20
        }
    },
    intelliproTitle: {
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 32
        },
        [theme.breakpoints.up('tableth')]: {
            paddingBottom: 80
        },
    },
    blogGroup: {
        [theme.breakpoints.up('tabletv')]: {
            margin: -12
        },
        [theme.breakpoints.up('tableth')]: {
            margin: -16
        },
        [theme.breakpoints.up('desktop')]: {
            margin: -20
        }
    },
    sliderTitle:{
        [theme.breakpoints.up('mob')]: {
            paddingBottom: 32,
            marginRight: 32,
            width: 320
        },
        [theme.breakpoints.up('tableth')]: {
            width: 480
        },
    },
    sliderContent:{
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            maxWidth: 600,
        },
    },
    greatePlace: {
        height: 480,
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
        },
        alignItems: 'center',
        backgroundColor: Color.intelligentBlue,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        
    },
    bottomContent: {
        [theme.breakpoints.up('mob')]: {
            paddingLeft: '32px'
        },
        [theme.breakpoints.up('tableth')]: {
            paddingLeft: '60px'
        },
        
    },
    bottomLine: {
        [theme.breakpoints.up('mob')]: {
            margin: '40px 0px 15px'
        },
        [theme.breakpoints.up('tableth')]: {
            margin: '40px 0px 15px'
        },
        
    },
    greatePlaceImg:{
        position: 'relative',
        [theme.breakpoints.up('mob')]: {
            top: -172,
            width: 80,
            paddingRight: '32px'
        },
        [theme.breakpoints.up('tabletv')]: {
            paddingRight: '60px'
        },
        [theme.breakpoints.up('tableth')]: {
            width: 126,
            top: -133
        },
        [theme.breakpoints.up('desktop')]: {
            width: 108,
            top: -148
        },
        [theme.breakpoints.up('largedesktop')]: {
            width: 126,
            top: -133
        },
    },
    bottomImage: {
        height: 480,
        objectFit:'cover',
        [theme.breakpoints.up('mob')]: {
            width: '100%',
        },
        [theme.breakpoints.up('desktop')]: {
            width: '50%',
            
        },
    },

    '@media screen and (max-width: 1320px)' : {
        sliderContent: {
            width: '100%',
            maxWidth:'none'
        }
    }
})

const pics = [
     Google,
 Apple,
 Amazon,
 Airbnb, 
 Pinterest, 
 Snap, 
 Youtube, 
 Linkedin,
 Alibaba, 
 Tencent, 
 Baidu,
 Waymo, 
 Cruise, 
 Didi, 
 TuSimple, 
 Lego, 
 Samsung, 
 Starbucks, 
 Goldman 
]

class Home extends React.Component {

    constructor() {
        super();
    }

    render() {
        const {classes, menuClicked, history} = this.props;

        return(
            <div>
                <Helmet>
                    <title>Intellipro Group - Global Integrated Talent Solutions</title>
                    <meta name="title" content="Intellipro Group - Global Integrated Talent Solutions" data-react-helmet="true"/>
                    <meta property="og:title" content="Intellipro Group - Global Integrated Talent Solutions" data-react-helmet="true"/>
                    <meta property="og:description" content="IntelliPro simplifies talent management with comprehensive recruitment and EOR services, streamlining your global hiring process for maximum efficiency." data-react-helmet="true"/>
                    <meta name="description" content="IntelliPro simplifies talent management with comprehensive recruitment and EOR services, streamlining your global hiring process for maximum efficiency." data-react-helmet="true"/>
                </Helmet>
                <MobHeader />
                <Header />
                <div className={classes.root}>
                    <div className={classes.buttonGroup}>
                        <SqrBtnMain icons={right_icon_white} content={'Recruiting & Staffing'} onClick={() => { history.push('/recruiting'); menuClicked(1)}}/>
                        <SqrBtnSec icons={right_icon_blue} content={'Managed Services'} onClick={() => { history.push('/service'); menuClicked(2)}}/>
                        <SqrBtnSec icons={right_icon_blue} content={'Job Seeker'} onClick={() => { history.push('/jobseeker');  menuClicked(3)}}/>
                    </div>
                    <div className={`${classes.introBlock} ${classes.block}`} style={{flexWrap:'wrap',}}>
                        <div style={{paddingBottom:'32px', marginRight: 32}}>
                            <div className="h1" role="heading" aria-level="1" >We are High-Tech</div>
                            <div style={{color:Color.intelligentBlue}} className="h1" role="heading" aria-level="1">and High-Touch</div>
                        </div>
                       <p className={`${classes.intro} normal`}>
                            IntelliPro is a comprehensive talent acquisition firm offering a full suite 
                            of recruiting services as well as outsourced business for 
                            a roster of clients ranging from top global corporations to the most 
                            innovative up-and-coming tech startups. Headquartered in the heart of 
                            Silicon Valley, IntelliPro has a rapidly growing footprint that enables us 
                            to expertly serve companies in all industries in all corners of the world. 
                            Our clients trust us because we combine the most high-tech talent 
                            sourcing methods with highly personalized care to deliver a service 
                            experience of the highest standard.
                       </p>
                    </div>
                    <div className={`${classes.block} ${classes.cardBlock}`} style={{flexWrap:'wrap'}}>
                        <div className={classes.cardTitle}>   
                            <div className="h1" role="heading" aria-level="1">Savvy Solutions</div>
                            <div className="h1" style={{color:Color.intelligentBlue}} role="heading" aria-level="1">For Every Scenario</div>
                        </div>
                        <div className={`${classes.block} ${classes.cardGroup}`} style={{flexWrap:'wrap'}}>
                            <ServiceCard icons={ico_talent} content={"Talent Acquisition"}/>
                            <ServiceCard icons={ico_manage_service} content={"Consulting Solutions"}/>
                            <ServiceCard icons={ico_recruitment} content={"Employer of Record (EOR)"}/>
                            <ServiceCard icons={ico_eor} content={"Human Resources Outsourcing (HRO)"}/>
                            <ServiceCard icons={ico_consulting} content={"Professional Employer Organization (PEO)"}/>
                            <ServiceCard icons={ico_business_process} content={"Business Process Outsourcing (BPO)"}/>
                        </div>
                    </div>
                    <div className={`${classes.cardBlock} ${classes.block}`} >
                        <div className={classes.intelliproTitle}>
                            <div className="h1" role="heading" aria-level="1">What Sets </div>
                            <div className="h1" role="heading" aria-level="1">
                                <span style={{color:Color.intelligentBlue}}>IntelliPro </span> 
                                <label> &nbsp; </label>
                                <span>Apart?</span>
                            </div>
                        </div>
                       <div className={`${classes.block} ${classes.blockGroup}`} style={{overflow:'auto'}}>
                            <BlogCard pic={blog1} title={'Speed and Agility'} content={'Looking for fast, flexible and fully integrated solutions? Whether you need one person or an entire project team, our recruiters and account managers have the knowledge and resources to respond to even the most ambitious hiring or staffing request with speed and precision. In fact, we offer a guarantee that we will submit the first candidate to clients within just 48 hours.'} />
                            <BlogCard pic={blog2} title={'A Deep and Diverse Global Talent Pool'} content={'Our robust AI-powered talent-matching software integrates with existing recruitment tools to streamline the talent acquisition process, enabling us to quickly zero in on the most skilled candidates for your unique needs. We also partner with many of the most renowned universities in the world to access the best and brightest emerging talent.'} />
                            <BlogCard pic={blog3} title={'A Personal Approach'} content={"We truly get to know our clients, their biggest pain points and their greatest goals. The potent combination of our personalized approach and our team's recruiting expertise provides the foundation of a seamless hiring experience for your company. We can ensure that you have just the right people in place at just the right time."} />
                       </div>
                    </div>
                    <div className={`${classes.cardBlock} ${classes.block}`} >
                        <div className={classes.block} style={{flexWrap:'wrap'}}>
                            <div className={classes.sliderTitle}>
                                <div className="h1" role="heading" aria-level="1">Great Companies</div>
                                <div className="h1" style={{color:Color.intelligentBlue}} role="heading" aria-level="1">Trust In Us</div>
                            </div>
                            <p className={`${classes.sliderContent} normal`}>We are extremely proud to partner with many of the world’s most recognized Fortune 500 corporations, game-changing startups and many growing midsize and small companies.</p>
                        </div>
                        <CompanySwiper pics={pics}/>
                    </div>
                </div>
                <div className={`${classes.cardBlock} ${classes.block}`} style={{flexWrap:'wrap', flexDirection:'row'}}>
                    <div className={classes.greatePlace}>
                        <div className={classes.bottomContent} style={{display:'flex', flexDirection:'column', alignItems:'inherit'}}>
                            <div className={`mainBottom h1`} style={{color: Color.white, maxWidth: 474}} role="heading" aria-level="1">Great Place To Work&trade; since 2022</div>
                            <div>
                                <div  className={classes.bottomLine} style={{width: 111, height:1, backgroundColor:Color.white }}></div>
                                <p className={`normal`} style={{color:Color.white, maxWidth: 474}} role="heading" aria-level="2">IntelliPro is honored to officially be designated as a Great Place To Work™ since 2022.</p>
                            </div>
                        </div>
                        <a href="http://www.greatplacetowork.com/certified-company/7038214" title="Rating and Review" target="_blank" rel="noreferrer">
                            <img src="https://www.greatplacetowork.com/images/profiles/7038214/companyBadge.png" alt="Review" width="120" className={classes.greatePlaceImg}/>
                        </a>
                    </div>
                    <div className={classes.greatePlace}>

                    </div>
                    {/* <img src={BottomImg} alt="great company" className={classes.bottomImage}></img> */}
                </div>
                
            </div>
        )
    }
}

export default withStyles(style)(withRouter(Home));